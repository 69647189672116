import { connect } from 'react-redux'
import { state_update } from '../actions'
import AiOptionSet from './AiOptionSet'
import {DEFAULT_STATE_VALUE} from '../constants';
import {param2sku} from '../logic/sku-ver2.js'

const mapStateToProps = (state, ownProps) => ({
  options: getOptions(state),
  ce:state.ce === DEFAULT_STATE_VALUE ?'D':state.ce,
  ds:state.ds === DEFAULT_STATE_VALUE ?'R':state.ds,
  bandS:band(state,'S'),
  bandC:band(state,'C'),
  dctw:state.dctw === DEFAULT_STATE_VALUE ?'0.25':state.dctw,
  head:state.head,
  title:state.title,
  site:state.currentSite,
  showBandOptions:state.band === '0' ? 'ai-hide' : 'ai-show',
  sh:state.sh === DEFAULT_STATE_VALUE ?'1':state.sh,
  optionSize: ownProps.optionSize === 'big' ? 120: 100,
  single_sc_option: (state['options_all'].indexOf('sc') !== -1 && state['sc'] === DEFAULT_STATE_VALUE) ? true : false,
  orientation:ownProps.orientation,
  options_all: state['options_all'].join('-')
  
  
})

function getOptions (state) 
{
  var res={};
  var all_options=state['options_all'];
  res['options_all'] = all_options;
  for (var i in all_options)
    {
      res['options_' + all_options[i]]= state['options_' + all_options[i]]
      res['value_' + all_options[i]]= state[all_options[i]]
    }

 // console.log('getOptions',state);  
  return res;  
}
function band (state,typeIn) 
{
   ////console.log('STraing',typeIn)
  /* let cev = state['ce']
  let cev2 = state['cec']
  if (cev !== 'D') {
    cev = 'C'
    cev2 = state['ce']
  } */
  var newsku=param2sku(
    //['0',state['ds'],cev,state['dctw'],cev2,
    ['0',state['ds'],state['ce'],state['dctw'],state['cec'],
    state['headc'],state['dq'],state['head'],state['sh'],state['pkb'],
    "D",state['rset'],state['rsetl'],    state['rsetc'],state['ren'],
    state['rmet'],state['hmetc'],state['smetc'],state['renl'],state['rs'],
    '1',state['store'],typeIn,state['rset'],    state['rsetc'],
    state['rsetl'],state['rmet'],state['smetc'],state['rs'],state['ren'],
    state['renl'],'1','1'
    ],3)
  ////console.log ('bandsku',newsku,state)
  
  return newsku[0];  
}

 

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: ( () => dispatch(state_update({attr:ownProps.attr,value:ownProps.value})))
 // onClick: () =>state_update({attr:ownProps.attr,value:ownProps.value})
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AiOptionSet)