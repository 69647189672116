
import {
    includeRule,
    excludeRule,
    hideOptionSet
  }
  from './rules_functions.js';
  
  
export function rules_ds(newState, state) {
    var newrule = includeRule(newState, state, 'ds')
    
    
    newrule = excludeRule(newState, state, [
        ['ce', ['C']],
        ['ds', [ "P", "E", "PR", "C"]]], newrule)

    newrule['show']  = true
    return newrule
}