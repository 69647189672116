import _ from 'lodash';
import {DEFAULT_STATE_VALUE,DEFAULT_K_SKU,DEFAULT_K_QSKU,DEFAULT_KO_SKU,DEFAULT_KO_QSKU,DEFAULT_K_QSKU2,DEFAULT_J_SKU,DEFAULT_J_QSKU,DEFAULT_J_QSKU2,DEFAULT_Z_QSKU,DEFAULT_ZO_QSKU,DEFAULT_K_URL,DEFAULT_J_URL,DEFAULT_Z_URL,DEFAULT_KO_URL,DEFAULT_PPL_URL,DEFAULT_ZO_URL} from './constants';
import {sku2array,param2sku} from './logic/sku-ver2.js'
import {
  get_default_sku
} from './reducers/utils';
// only this file exports two functions, which are the 'bridge' between the URL and app state:

// mapLocationToState takes location object and app state,
//   and returns new app state with location params merged into it

// mapStateToLocation takes app state and location,
//   and returns new location updated from app state

// functions necessary for parsing the URL parameters (eg. dates, numbers) can be implemented in here,
// or if more complicated, can be specified more rigidly somewhere else and called here




export function mapLocationToState(state, location) {
  ////console.log('history URL>State',state,location)  
//  var state2={};
  switch(location.pathname) {
    
    case "/":
      let stateFromLocation = location.query;

      if (window.currentEnv === 'www' && stateFromLocation['hc'] === '0' && stateFromLocation['hs'] === '0' && stateFromLocation['hu'] === '0'){
        if (window.currentSite === 'kay') {
          window.location.href = DEFAULT_K_URL;
        }
        else if (window.currentSite === 'jared') {
          window.location.href = DEFAULT_J_URL;
        }
        else if (window.currentSite === 'zales') {
          window.location.href = DEFAULT_Z_URL;
        }
        else if (window.currentSite === 'zalesoutlet') {
          window.location.href = DEFAULT_ZO_URL;
        }
        else if (window.currentSite === 'kayoutlet') {
          window.location.href = DEFAULT_KO_URL;
        }
        else if (window.currentSite === 'peoplesjewellers') {
          window.location.href = DEFAULT_PPL_URL;
        }
        else {
          window.location.href = DEFAULT_Z_URL;
        }
      }
    
     // state.initial_load=true
     state.loading = 'new'
         
     //console.log('DECODE START URL',stateFromLocation)

     let new_sku=get_default_sku()
     if (stateFromLocation === undefined || ([DEFAULT_K_QSKU,DEFAULT_K_QSKU2,DEFAULT_J_QSKU,DEFAULT_J_QSKU2,DEFAULT_Z_QSKU,DEFAULT_ZO_QSKU].includes(stateFromLocation['zalessku']) && stateFromLocation['sku'] === get_default_sku() && stateFromLocation['cfg'] === DEFAULT_STATE_VALUE
    ) ) {
     stateFromLocation['initial']='yes';
     stateFromLocation['initial-qsku']=stateFromLocation['zalessku']}
   ////console.log('DECODE initial',stateFromLocation['initial'])
     
     if (stateFromLocation !== undefined) {
      if (!['true','false'].includes(stateFromLocation['isposterminal']) )
          stateFromLocation['isposterminal']= 'false'
      let new_sku2 =decodeURIComponent(stateFromLocation['sku']) 

     

      if (new_sku2 !== undefined && new_sku2 !== '') 
       new_sku =  new_sku2
     //let allengr=['er','eb1']
      //for (let i in allengr ) {
       // if (stateFromLocation[allengr[i]] !=='') {
       //  console.log('==',i,allengr[i],allengr)
          //stateFromLocation[allengr[i]] = decodeURIComponent(stateFromLocation[allengr[i]])
        // }
       //}
       let allud=['dct','dc','dcl','dl','dw','dd']
      for (let i in allud ) {

        if (stateFromLocation[allud[i]] !=='') {
          ////console.log('adding',allud[i],stateFromLocation[allud[i]],'+')
          stateFromLocation[allud[i]] = decodeURIComponent(stateFromLocation[allud[i]])
         }
       }
      
       
       }
      else {
        stateFromLocation ={'dct':'','dc':'','dcl':'','dl':'','dw':'','dd':'','er':'','eb1':'','isposterminal':'false','ud':'D'} 
      }
     
     //dctw: '0.25',
    //console.log('load from url',currentSite)
        stateFromLocation['initzalessku'] = stateFromLocation['zalessku']
    if (window.currentSite === 'peoplesjewellers' ||window.currentSite === 'kayoutlet'||window.currentSite === 'zalesoutlet') 
          stateFromLocation.zalessku='' 
    if (stateFromLocation.zalessku === '') {
     
    stateFromLocation['sku'] =  new_sku
     ////console.log('from location.js 74',stateFromLocation.sku,stateFromLocation.zalessku)
     let refParam = sku2array(new_sku) 
     var list_extra=[]
     //if (stateFromLocation['zalessku'] ==='')
     for (let i1 in list_extra ){
       refParam[list_extra[i1]] =state[list_extra[i1]]
     }

     
     for (let i in refParam )
     {
        //console.log(i, refParam[i])
        if (i == 'ce' && refParam[i] === 'UD') {
          stateFromLocation['ce'] = 'D'
          stateFromLocation['ud'] = 'U'
          stateFromLocation['isposterminal'] = 'true' 
        }
        else {
          stateFromLocation[i] = refParam[i]
        }
     }
    }
    else if (stateFromLocation.zalessku !== '' && ['kay', 'jared','kayoutlet','zales','zalesoutlet','peoplesjewellers'].indexOf(window.currentSite) !== -1) {
      let z_sku = stateFromLocation.zalessku
      if (window.currentSite === 'kay') {
          if (z_sku === DEFAULT_K_QSKU)
              z_sku = DEFAULT_K_SKU
          else if (z_sku === DEFAULT_K_QSKU2)
              z_sku = '2DSZVHIT1YS0B'
      }
      else if (window.currentSite === 'kayoutlet') {
        //if (z_sku === DEFAULT_KO_QSKU)
            z_sku = DEFAULT_KO_SKU
      }    else if (window.currentSite === 'zalesoutlet') {
      //if (z_sku === DEFAULT_KO_QSKU)
      z_sku = '2Y1EIPWOQPN0A'
    }    else if (window.currentSite === 'zales') {
      z_sku = '2HCQHOQOEYX0A'
      
  }
    else if (window.currentSite === 'jared') {
          if (z_sku === DEFAULT_J_QSKU)
              z_sku = DEFAULT_J_SKU
          else if (z_sku === DEFAULT_J_QSKU2)
              z_sku = '2MYMN0B'
      }

      stateFromLocation['initial'] = '';
    //  stateFromLocation['zalessku'] = ''
      stateFromLocation['sku'] =  z_sku

       console.log('from location.js 74',stateFromLocation.sku,stateFromLocation.zalessku)
       let refParam = sku2array(z_sku) 

      if (refParam['rs'] !== '0') {
        refParam['rs'] = '0'
        var newsku = param2sku(['R',refParam['ds'],refParam['ce'],refParam['dctw'],refParam['cec'],refParam['headc'],refParam['dq'],refParam['head'],refParam['sh'],refParam['pkb'],"D",refParam['rset'],refParam['rsetl'],refParam['rsetc'],refParam['ren'],refParam['rmet'],refParam['hmetc'],refParam['smetc'],refParam['renl'],refParam['rs'],'1',refParam['store'],refParam['band'],refParam['bset'],refParam['bsetc'],refParam['bsetl'],refParam['bmet'],refParam['bmetc'],refParam['rs'],refParam['ben'],refParam['benl'],'1','1'],2)
        z_sku = newsku[0]
        stateFromLocation['sku'] =  z_sku
      }

       var list_extra=[]
       //if (stateFromLocation['zalessku'] ==='')
       for (let i1 in list_extra ){
         refParam[list_extra[i1]] =state[list_extra[i1]]
       }
  
       
       for (let i in refParam )
       {
        //console.log(i, refParam[i])
        if (i == 'ce' && refParam[i] === 'UD') {
          stateFromLocation['ce'] = 'D'
          stateFromLocation['ud'] = 'U'
          stateFromLocation['isposterminal'] = 'true' 
        }
        else {
          stateFromLocation[i] = refParam[i]
        }
       }
      }
     return _.merge({}, state, stateFromLocation);

    default:
      ////console.log('default history')
      return state;
  }
}


// this is just a rough sketch of possible transformers.
// real ones could be much bigger or smarter,
// and could use schemas or constants files more effectively,
// but the point is having one location where this happens

export const paramSetup = {
  global: {
    action: {stateKey: 'action',  options: {shouldPush: true},initialState: 'redirect'},
    hs: {stateKey: 'hs',  options: {shouldPush: true},initialState: DEFAULT_STATE_VALUE},
    hc: {stateKey: 'hc',  options: {shouldPush: true},initialState: DEFAULT_STATE_VALUE},
    hu: {stateKey: 'hu',  options: {shouldPush: true},initialState: DEFAULT_STATE_VALUE},
    he: {stateKey: 'he',  options: {shouldPush: true},initialState: DEFAULT_STATE_VALUE},
    sku: {stateKey: 'sku',  options: {shouldPush: true},initialState: get_default_sku()},
    cfg: {stateKey: 'cfg',  options: {shouldPush: true},initialState: DEFAULT_STATE_VALUE},
    active_view: {stateKey: 'active_view',  options: {shouldPush: true},initialState: '1'},
    er: {stateKey: 'er',  options: {shouldPush: true},initialState: ''},
    eb1: {stateKey: 'eb1',  options: {shouldPush: true},initialState: ''},
    ud: {stateKey: 'ud',  options: {shouldPush: true},initialState: 'D'},
    dc: {stateKey: 'dc',  options: {shouldPush: true},initialState: ''},
    dcl: {stateKey: 'dcl',  options: {shouldPush: true},initialState: ''},
    dct: {stateKey: 'dct',  options: {shouldPush: true},initialState: ''},
    dl: {stateKey: 'dl',  options: {shouldPush: true},initialState: ''},
    dw: {stateKey: 'dw',  options: {shouldPush: true},initialState: ''},
    dd: {stateKey: 'dd',  options: {shouldPush: true},initialState: ''},
    zalessku: {stateKey: 'zalessku',  options: {shouldPush: true},initialState: ''},
    initial: {stateKey: 'initial',  options: {shouldPush: true},initialState: ''},
    isposterminal: {stateKey: 'isposterminal',  options: {shouldPush: true},initialState: 'false'},
    imageversion: {stateKey: 'imageversion',  options: {shouldPush: true},initialState: '5'},
    icid: {stateKey: 'icid',  options: {shouldPush: true},initialState: ''},
    cid: {stateKey: 'cid',  options: {shouldPush: true},initialState: ''}
  
    
   
    
  }
};