import React, { Component } from 'react';
import AiOptionWrap from "./AiOptionWrap";
import AiInputWrap from "./AiInputWrap";
import AiInputWrapDia from "./AiInputWrapDia";
import AiReviewWrap from './AiReviewWrap';
import { SPECIAL_NOTE_SMALL,SPECIAL_NOTE_SMALL_PPL } from '../constants';
import { SPECIAL_NOTE_ACTIVATE } from '../constants';
//import { SPECIAL_NOTE_SMALL2 } from '../constants';
//import { SPECIAL_NOTE_LINK } from '../constants';

var orientation = ''

export function scrollToOption(option) {
  if (option == 'rs') {
    var element = document.getElementsByClassName('ai-name-size')[0]}
  else {
    var element = document.getElementById(option)}
  var rect = element.getBoundingClientRect();
  var windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  var offset = parseInt(rect.height, 10) / 2 - windowHeight / 2;
  if (orientation === 'portrait')
    offset -= 295/2;
  var top = 0;
  
  do {
      top += parseInt(element.offsetTop, 10) || 0;
      element = element.offsetParent;
  } while(element);

  let y = top + offset;
  if ((getLowerBound() < y) || ((getLowerBound() - y) <= 15))
    y = getLowerBound();

  /* console.log('default', 'window.scrollTo(0, ' + (top + offset).toFixed(0) + ');')
  console.log('bounds', 'window.scrollTo(0, ' + getLowerBound().toFixed(0) + ');') */
  
  window.scrollTo({ top: y.toFixed(0), left: 0, behavior: 'smooth'});
}

function getLowerBound() {
  var element;
  var offset;
  if (orientation !== 'portrait') {
    element = document.querySelector('.ai-right-panel')
    offset = 5;
  }
  else {
    element = document.querySelector('.ai-main-body')
    offset = 15;
  }

  var rect = element.getBoundingClientRect();
  var windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  var top = 0;

  do {
      top += parseInt(element.offsetTop, 10) || 0;
      element = element.offsetParent;
  } while(element);
  
  return top + parseInt(rect.height, 10) - windowHeight + offset;
}

//import {Accordion} from 'primereact/components/accordion/Accordion';
//import {AccordionTab} from 'primereact/components/accordion/Accordion';


class AiOptionSet extends Component {
  
  constructor(props) {
      super(props);
      orientation = this.props.orientation;
  }
  componentDidUpdate() {
    if (orientation !== this.props.orientation && this.props.orientation !== undefined) {
      orientation = this.props.orientation;
    }
  }
//test
  render() {
    ////console.log('get all options',this.props.options);
    var rows = [];
    //var bandrows = [];
    var last =0 
    var cur_option
    if (this.props.options['options_all'] !== undefined) {
      last = this.props.options['options_all'].length -1
    }  
    for (let i in this.props.options['options_all']) {
      cur_option= this.props.options['options_all'][i]
      ////console.log('last value',cur_option,parseInt(i,10),last, this.props.single_sc_option)
      var lastValue ='false'
      if (parseInt(i,10)===last || (cur_option === "sc" && this.props.single_sc_option )) {
        lastValue = 'true' 
        ////console.log('let lastValue be TRUE')
      } 

      var cur_details= this.props.options['options_' + cur_option]
      var cur_details_options= []
      for (let i1 in cur_details.options)
      {
         //console.log('let lastValue be TRUE',cur_details.options[i1])
        if (cur_details.options[i1].hasOwnProperty('show')===false || cur_details.options[i1]['show']===true){
        // console.log('PUSHED',cur_details.options[i1].name)
        cur_details_options.push(cur_details.options[i1])
        }
      }

      
      ////console.log('cur_option',cur_option,this.props.options)
      var cur_name = cur_details.name
      if (cur_details.option_name === 'stone') {
        cur_name = "Stone"
        
      }
      var cur_value = this.props.options['value_' + cur_option]
      var band_attrs=["band","bset","bsetc","bsetl","ben","bmet","bmetc","eb1"]
      
      var red_note ='' 
      if (SPECIAL_NOTE_ACTIVATE){
         red_note =SPECIAL_NOTE_SMALL
         if (window.currentSite === 'peoplesjewellers') 
            red_note =SPECIAL_NOTE_SMALL_PPL
      }
      //var red_note =SPECIAL_NOTE_SMALL
      //var red_note1=''
      //var red_note2=''
      //var red_link=''
      ////console.log('SITES',this.props.site)
      /*if (this.props.site === 'kay'){
        red_note1 =NOTE_SMALL1
        red_note2 =SPECIAL_NOTE_SMALL2
        red_link =<a href={SPECIAL_NOTE_LINK}>click here</a> 
      }*/
      var option_name =
       this.props.options['options_' + cur_option].option_name !== undefined ? ' ai-name-'+this.props.options['options_' + cur_option].option_name: ''
   // console.log('this.props.option_name',cur_details,cur_details_options)
    var maxSliderSize = (this.props.optionNumber * this.props.optionSize + 60) +'px';
    var maxSliderSizeNum = this.props.optionNumber * this.props.optionSize 
      if (cur_details.show)
       switch (cur_details.type) {
      
        case 'select':
        case 'control':
          ////console.log('AIOptions',this.props)

          if (band_attrs.indexOf(cur_option) === -1) 
           rows.push(<AiOptionWrap
            key={cur_option} 
            name={cur_name} 
            attr={cur_option}
            ce={this.props.ce}
            css={cur_details.css}
            ds ={this.props.ds}
            dctw ={this.props.dctw}
            head ={this.props.head}
            bandC ={this.props.bandC}
            bandS ={this.props.bandS}
            sh ={this.props.sh}
            children={cur_details_options} 
            optionNumber={this.props.optionNumber} 
            optionSize={this.props.optionSize}
            optionName={option_name}
            initialValue={cur_value}
            image_base={cur_details.image_base}
            image_postfix={cur_details.image_postfix}
            last= {lastValue}
            options_all={this.props.options_all}
            orientation={this.props.orientation}/>);
           else
            rows.push(<AiOptionWrap
              key={cur_option} 
              name={cur_name} 
              attr={cur_option}
              ce={this.props.ce}
              css={cur_details.css}
              ds ={this.props.ds}
              dctw ={this.props.dctw}
              head ={this.props.head}
              sh ={this.props.sh}
              children={cur_details_options} 
              optionNumber={this.props.optionNumber} 
              optionSize={this.props.optionSize}
              optionName={option_name}
              initialValue={cur_value}
              image_base={cur_details.image_base}
              image_postfix={cur_details.image_postfix}
              last= {lastValue}
              options_all={this.props.options_all}
              orientation={this.props.orientation}/>);
              break;
        case 'inputDia':
              if (band_attrs.indexOf(cur_option) === -1) 
                rows.push(<AiInputWrapDia
                  key={cur_option} 
                  name={cur_details.name} 
                  children={cur_details.options} 
                  
                  />);
                  break;

        case 'input':
        if (band_attrs.indexOf(cur_option) === -1) 
          rows.push(<AiInputWrap 
            key={cur_option} 
            name={cur_details.name} 
            product='ring'
            maxSliderSize ={maxSliderSizeNum}
            children={cur_details.options} 
            options_all={this.props.options_all}
           
            
        //    optionNumber={this.props.optionNumber} 
        //    optionSize={this.props.optionSize}
            />);
            else
            rows.push(<AiInputWrap 
                key={cur_option} 
                name={cur_details.name} 
                maxSliderSize ={maxSliderSizeNum}
                product='band'
                children={cur_details.options} 
                options_all={this.props.options_all}
                />);
               
            break;
        default:
            break;
        
      }
      
    }
    var addchat=''
    //<!--div key="patent1" className="separator2"><p>
    //{addchat}  </p></div-->
    //      if (h>w)
              addchat= 
              <div className="ai-chat-block">
              <div className="ai-chat-icon">
                                     <img alt='chat' width='45px' height='45px' src="//static.thepersonalizedbest.com/pers4/chat.jpg"></img>
              </div>
              <div className="ai-chat-text">
                             <p>Have a question about {this.props.title}?</p>
                             <p><a href="javascript:document.getElementsByClassName('LPMcontainer')[0].click();" >Chat Now</a></p>
              </div>
              </div> 
    return (
      <div className='ai-option-wrap' style={{width:maxSliderSize}}>
      {rows}
     
      <AiReviewWrap/>

      <div className='ai-special-note'><font color='red'>{red_note}</font></div>
      {/*<div className='ai-bottom'/>*/}
      <div key="patent" className="separator2a">&nbsp;&nbsp;</div>
      
       
    {addchat} <p/>
     <div key="patent2" className="separator2">&nbsp;Patent Pending Technology&nbsp;</div><p></p>
     </div>
    );
  }
}
export default AiOptionSet;