import {sku2array} from './logic/sku-ver2.js'
import React, {Component} from 'react';
//import logo from './logo.svg';
import AiLayout from './layout/AiLayout';
import 'font-awesome/css/font-awesome.css';
//import loadJson from './loadJson';
//import MediaQuery from 'react-responsive';
import {Messages} from 'primereact/components/messages/Messages';
import {Growl} from 'primereact/components/growl/Growl';
//import {Message} from 'primereact/components/message/Message';
import './ai_layout.css'
import 'primereact/resources/themes/omega/theme.css';
import 'primereact/resources/primereact.min.css';
import './App.css';
//import  from 'react-ga';
import ReactGA from "react-ga4";
//import 'primeicons/primeicons.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
    CFG_URL,
    CFG_URL_PROD,
    ZALES_SKU_URL,
    ZALES_SKU_URL_PROD,
    DEFAULT_STATE_VALUE
} from './constants';

import {
    header_footer_get_parameters
} from './header_footer_parameters';
import {
    get_default_sku
} from './reducers/utils';

//import {config_zales }  from './config/zales.js'
import {config_kay }  from './config/kay.js'
import {config_kayB }  from './config/kay-testB.js'
import {config_kay10 }  from './config/kay-test10.js'
import {config_kay11 }  from './config/kay-test11.js'
import {config_kay12 }  from './config/kay-test12.js'
import {config_kay13 }  from './config/kay-test13.js'
import {config_kay23 }  from './config/kay-test23.js'
//console.log('LOCATION',window.currentSite)
//default value
header_footer_get_parameters('')

if (window.currentEnv === 'www' && ['zalesoutlet','zales','kay','kayoutlet','jared','peoplesjewellers'].includes(window.currentSite)) {
    if ('zalesoutlet' ===window.currentSite)    ReactGA.initialize('G-6Q4M2CMG72');
    if ('zales'===window.currentSite)     ReactGA.initialize('G-2YYJ9VM6WP');
    if ('kay'===window.currentSite)     ReactGA.initialize('G-E2Z13XZ648');
    if ('jared'===window.currentSite)     ReactGA.initialize('G-SH8JJSJQBS');
    if ('kayoutlet'===window.currentSite)     ReactGA.initialize('G-QKQ9X2EKK5');
    if ('peoplesjewellers'===window.currentSite)     ReactGA.initialize('G-DDJGYGWDCW');
}
else {
        ReactGA.initialize('G-RHFTFXZT76'); //test
    }
    
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Customizer" });
      console.log('GA Test',ReactGA._currentMeasurementId) 


class App extends Component {
//    constructor() {
//        super();
//    }
    constructor(props) {
        super(props);
        this.state = {
        //    zalessku:'',
            error: false,
            height: document.documentElement.clientHeight,
            width: document.documentElement.clientWidth,
//    width: window.innerWidth,
            //layout: <div/>
        };
        this.updateDimensions = this.updateDimensions.bind(this);
        //this.props.onUpdate('site',window.currentSite)
        //console.log('constructor APP', window.currentSite)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        var w = document.documentElement.clientWidth
        var h = document.documentElement.clientHeight
        this.setState({
            height: h,
            width: w
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
    //    UNSAFE_componentWillReceiveProps(nextProps) {
            //console.log('App will receive Props', nextProps)
        //console.log('will update App', window.innerWidth, 'x', window.innerHeight)
    //    if (this.messages !== undefined && this.props.push_message !== undefined) {
      if (this.messages !== undefined  && nextProps.push_message !== undefined && nextProps.push_message !== '') {
       //         //console.log('App will receive Props3', this.props.push_message.detail)
          //console.log('App will receive nextProps3',  nextProps.push_message,'end')
               this.growl.show({
                severity: nextProps.push_message.severity,
                summary: nextProps.push_message.summary,
                sticky: nextProps.push_message.sticky === true ? true : false,
                detail: nextProps.push_message.detail,
                life:'30000'
            });
            this.props.onRemoveMessage()
        }
        this.props.onHistory(nextProps['sku']);
       

    }
    adobeStat (sku,skuPrice) {
       //console.log('Nexus', sku,skuPrice,skuName)
       const script = document.createElement("script");
        let env =window.currentEnv
        if (env === 'www')
            env='prod'
        else    
            env='stage'
        if (env === 'prod')
            script.src = "//assets.adobedtm.com/0d1bad3c8952/c2de8c06f41b/launch-e45de778e483.min.js"
        else
            script.src = "//assets.adobedtm.com/0d1bad3c8952/c2de8c06f41b/launch-82c3619725c9-development.min.js"

            
        script.async = true;
        script.id = 'aiadobe';

        document.head.appendChild(script);

        const script2 = document.createElement('script');
        script2.type = 'text/javascript';
        script2.async = true;
        let nexusSite = window.currentSite
        let nexusSiteTitle = nexusSite.substring(0,1).toUpperCase() + nexusSite.slice(1)
        // 2000 for Kay, 2032 for KayOutlet, 2001 for Jared, 0008 for ZalesOutlet
        //let stext1 = 'window.adobeDataLayer.push({'+
        let stext1 = ' var dataLayer = new Object();dataLayer = {'+
          '"event":"prodView","pageInfo": {"deviceType":"Mobile","hierarchy":"Home, Bridal Configurator","pageName":"Bridal Configurator | '+nexusSiteTitle+'","pageType":"Product","server":"bridal.'+nexusSite+'.com"  },'+
'"store": {"retailerName":"'+nexusSite+'","salesChannel":"Website","storeAssisted":"","storeID":"0002"  },'+
'"product": {"productListItems":[{"baseProduct":"V-' + sku +'","brand":"'+nexusSite+'",  "description":"","inventoryLevel":"","origPrice":"'+skuPrice+'","outOfStock":"","parentProduct":"","personalization":"",'+
'"productCategory":"Custom Bridal","productClass":"","productName":"Bridal Customizer","productSKU":"' + sku +'","quantity":"1","rating": "","ratingCount":"","salePrice":"'+skuPrice+'","startingPrice":"'+skuPrice+'",'+
'"title":"'+nexusSiteTitle+'"	}]},'+
'"user": {"asmLoggedIn":"false","city":"","country":"","email":"anonymous","guestID":"","lastLoginDate":"","loginMethod":"","loginSuccess":"","loyaltyID":"","loyaltyMember":"false","scid":"",'+
'"sessionID":"'+this.props.hu + '","state":"","userId":"anonymous","userStatus":"G","zipcode":""},"eventDetails": {"oosCart_e1":"0",  "prodView":"1"}};'+
 'var scr =document.querySelector("#aiadobe");scr.addEventListener("load",function() {window.adobeDataLayer.push(dataLayer)});'
     
        const scriptText = document.createTextNode(stext1);
        script2.appendChild(scriptText);
        document.head.appendChild(script2);
    //    console.log('script data',stext1)
    }
    componentDidMount() {
        //console.log('json4site',json4site)

        window.addEventListener("resize", this.updateDimensions);
        //window.addEventListener("resize", this.handleResize);
        //console.log('handleResize did mount')
        window.testAB='0'
        window.rset='-1'
        let mode = 'sku'
        let header = new Headers({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data'
        });
        let sentData = {
            method: 'GET', //opt.method,
            mode: 'cors',
            header: header,

            //    body:opt.body || ''
        };
        
        //console.log('json4site',json4site)
        if (this.props.cfg !== DEFAULT_STATE_VALUE && this.props.cfg !== undefined) {
            let params = '?cfg=' + this.props.cfg
            mode = 'cfg'
            let fetchUrl = CFG_URL
            if (window.currentEnv ==='www'){
                fetchUrl = CFG_URL_PROD
            }
          
                fetch(fetchUrl + params, sentData)
                    .then(response => {
                        if (response.ok) {
        
                            return response.json();
                        }
                        else {
                            this.setState({error: true})
                            this.messages.show({
                                severity: 'error',
                                sticky: true,
                                summary: 'Error loading item info: ',
                                detail: 'Cannot load data'
                            });
                            throw new Error('Something went wrong ...');
                        }
                    })
                    .then(json => {
                     //console.log('got json',json)
                        if (Object.keys(json).length === 0) {
                            this.setState({error: true})
                            this.messages.show({
                                severity: 'error',
                                sticky: true,
                                summary: 'Error loading item info: ',
                                detail: 'No load data'
                            });
                            //throw new Error('Something went wrong ...');
                        }
                        
            var jsonAll ={}
            
            //console.log('loaded start')    
     //       if (['zales', 'peoplesjewellers'].indexOf(window.currentSite) !== -1) 
      //          {
      //          jsonAll= JSON.parse(JSON.stringify(config_zales))
            //    //console.log('loaded zales')    
       //         }
      //      else if (['kay', 'jared'].indexOf(window.currentSite) !== -1) 
       //         {
           jsonAll= JSON.parse(JSON.stringify(config_kay10));                 
               //console.log('loaded kay')    
         //       }
            jsonAll['currentSite']=window.currentSite
            var json2 = JSON.parse(JSON.stringify(jsonAll));
            jsonAll.start_over_init = json2
          //console.log('loaded end',jsonAll)    
            for (let key in json){
                    jsonAll[key] = json[key]
            }
            try {
                //console.log('from App.js 207')
                let refParam = sku2array(json['sku']) 
                for (let i in refParam )
                {
                //console.log('param',i,refParam[i])
                 jsonAll[i]= refParam[i]
                }
                  }
              catch(err) {
            //console.log('wrong sku from cfg',json['sku'])
            }
            json['currentSite']=window.currentSite
               
            this.props.onJson(jsonAll);
           //console.log('init set jsonAll cfg',jsonAll)
//                        json['loading'] = 'new'
              //console.log('init event',mode,json['style'])
                 /*       ReactGA.event({
                            category: 'init-' +mode,
                            action:json['sku']
                        });*/
            })}



           // console.log('init set json0',window.currentSite,this.props.zalessku )
            var sku = this.props.sku
            if (this.props.zalessku !== ''){
                if (window.currentSite === 'zales' ||window.currentSite === 'zalesoutlet') {
                    let fetchUrl =ZALES_SKU_URL
                    if (window.currentEnv ==='www'){
                        fetchUrl =ZALES_SKU_URL_PROD
                    }
                    //convert zales sku to anisku
                    fetch(fetchUrl + this.props.zalessku + '&site='+ window.currentSite, sentData)
                            .then(response => {
                                if (response.ok) {
                                    console.log('response got json zales')
                                    return response.json();
                                }
                            })
                            .then(json => {
                                if (json['code'] ==='0') {
                                //console.log('got json zales',json)
                                    this.props.onZsku(json['sku']);
                                }
                                else
                                {
                                this.props.onZsku(get_default_sku());
                                }
                                })
                }
                /* else {
                    let z_sku = this.props.zalessku
                   
                    else if (window.currentSite === 'kay') {
                        if (z_sku === DEFAULT_K_QSKU)
                            z_sku = '2DX6BXUUCAI0H7Z'
                        else if (z_sku === DEFAULT_K_QSKU2)
                            z_sku = '2DSZVHIT1YS0B'
                    }
                    else if (window.currentSite === 'jared') {
                        if (z_sku === DEFAULT_J_QSKU)
                            z_sku = '2BBDDJOO1RN0BUTA'
                        else if (z_sku === DEFAULT_J_QSKU2)
                            z_sku = '2BBDDJOO1RN0BUTB'
                    }
                    
                    if (z_sku.startsWith('2')) {
                        this.props.onZsku(z_sku)
                    }
                    else {
                        this.props.onZsku(get_default_sku());
                    }
                } */
            }
            
            
            if (mode === 'sku' 
            //&& this.props.zalessku === ''
            ){
                
                var jsonAll = '' //JSON.parse(JSON.stringify(config_zales));
                
               var testAB=    Math.floor(Math.random() * 10);
               //  console.log('testAB',testAB)
              //   if  (testAB <5 )
              if  (this.props.imageversion==='6' )
              {
                  window.testAB='23'
                  jsonAll= JSON.parse(JSON.stringify(config_kay23));   
                  /*ReactGA.event({
                   #   category: 'testA',
                   #   action:this.props.sku
                  });*/
              }    
              else if  (this.props.imageversion==='10' )
                 {
                     window.testAB='10'
                     jsonAll= JSON.parse(JSON.stringify(config_kay10));   
                     /*ReactGA.event({
                      #   category: 'testA',
                      #   action:this.props.sku
                     });*/
                 }    
                 else if  (this.props.imageversion==='11' )
                 {
                     window.testAB='11'
                     jsonAll= JSON.parse(JSON.stringify(config_kay11));   
                     
                 }    
                 else if  (this.props.imageversion==='12' )
                 {
                     window.testAB='12'
                     jsonAll= JSON.parse(JSON.stringify(config_kay12));   
                     
                 }    
                 else if  (this.props.imageversion==='13' )
                 {
                     window.testAB='13'
                     jsonAll= JSON.parse(JSON.stringify(config_kay13));   
                     
                 }    
                 else
                 {
                     window.testAB='B'
                     jsonAll= JSON.parse(JSON.stringify(config_kay10));    
                  ReactGA.event({
                     category: 'testB',
                     action:this.props.sku 
                 });  }                  
                 console.log('loaded',window.testAB)    
                 //   }
                var json2 = JSON.parse(JSON.stringify(jsonAll));
                jsonAll.start_over_init = json2
                jsonAll['sku'] =sku // check if sku is correct
                jsonAll['currentSite']=window.currentSite
            
                this.props.onJson(jsonAll);
             //console.log('init set jsonAll sku',jsonAll)
                ReactGA.event({
                    category: 'init-' +mode,
                    action:this.props.sku
                });
            }    
            //console.log('init set jsonAll sku',jsonAll)
            console.log('testab',window.testAB)
            let statsku=this.props.zalessku
            
            if (statsku === '')
                statsku=this.props.sku

            if (['jared'].includes(window.currentSite))
                this.adobeStat(statsku,1748.06)
            else {if (['kay','kayoutlet'].includes(window.currentSite))
                this.adobeStat(statsku,1823.06)
            else    
               this.adobeStat(statsku,1605.78)
        }

            //if (this.props.initAttr !== false)
            //        this.props.onLoad(this.props.initAttr, this.props.initValue)
            this.props.onImage(1)
            

    }

    render() {


        var layout;
        //console.log('on render App', this.props.push_message)
        var layoutDataP = [
            {
                size: 350,
                header: 'medium',
                footer: 'medium',
                orientation: 'portrait',
                image: 'small',
                optionNumber: 2,
                optionSize: 'small'
            },
            {
                size: 500,
                header: 'medium',
                footer: 'medium',
                orientation: 'portrait',
                image: 'medium',
                optionNumber: 3,
                optionSize: 'small'
            },
            {
                size: 600,
                header: 'medium',
                footer: 'medium',
                orientation: 'portrait',
                image: 'medium',
                optionNumber: 4,
                optionSize: 'small'
            },
            {
                size: 720,
                header: 'medium',
                footer: 'medium',
                orientation: 'portrait',
                image: 'medium',
                optionNumber: 4,
                optionSize: 'small'
            },
            {
                size: 820,
                header: 'medium',
                footer: 'medium',
                orientation: 'portrait',
                image: 'big',
                optionNumber: 5,
                optionSize: 'small'
            },
            {
                size: 900,
                header: 'medium',
                footer: 'medium',
                orientation: 'portrait',
                image: 'big',
                optionNumber: 6,
                optionSize: 'small'
            },
            {
                size: 1000,
                header: 'medium',
                footer: 'medium',
                orientation: 'portrait',
                image: 'big',
                optionNumber: 7,
                optionSize: 'small'
            },
            {
                size: 1150,
                header: 'medium',
                footer: 'medium',
                orientation: 'portrait',
                image: 'big',
                optionNumber: 8,
                optionSize: 'small'
            },
            {
                size: 1300,
                header: 'medium',
                footer: 'medium',
                orientation: 'portrait',
                image: 'big',
                optionNumber: 9,
                optionSize: 'small'
            },
            {
                size: 2000,
                header: 'medium',
                footer: 'medium',
                orientation: 'portrait',
                image: 'big',
                optionNumber: 10,
                optionSize: 'small'
            },
        ]
        var layoutDataL = [
            {
                size: 350,
                header: 'medium',
                footer: 'medium',
                orientation: 'portrait',
                image: 'medium',
                optionNumber: 2,
                optionSize: 'small'
            },
            {
                size: 590,
                header: 'medium',
                footer: 'medium',
                orientation: 'portrait',
                image: 'medium',
                optionNumber: 3,
                optionSize: 'small'
            },
            {
                size: 650,
                header: 'medium',
                footer: 'medium',
                orientation: 'landscape',
                image: 'medium',
                optionNumber: 3,
                optionSize: 'small'
            },
            //{size:700,header:'medium', footer:'medium', orientation:'landscape', image:'medium', optionNumber:3, optionSize:'small'},
            {
                size: 760,
                header: 'medium',
                footer: 'medium',
                orientation: 'landscape',
                image: 'medium',
                optionNumber: 3,
                optionSize: 'small'
            },
            {
                size: 1000,
                header: 'medium',
                footer: 'medium',
                orientation: 'landscape',
                image: 'medium',
                optionNumber: 3,
                optionSize: 'small'
            },
            {
                size: 1300,
                header: 'medium',
                footer: 'medium',
                orientation: 'landscape',
                image: 'medium',
                optionNumber: 4,
                optionSize: 'small'
            },
            {
                size: 1400,
                header: 'medium',
                footer: 'medium',
                orientation: 'landscape',
                image: 'big',
                optionNumber: 6,
                optionSize: 'small'
            },
            {
                size: 2000,
                header: 'big',
                footer: 'big',
                orientation: 'landscape',
                image: 'big',
                optionNumber: 7,
                optionSize: 'small'
            },
        ]
       //console.log('APP -> windows size',this.state.width,this.state.height)
        if (this.state.error === false && this.props.json !== undefined) {
            var i;
            let w = this.state.width
            let h = this.state.height
            // let w = window.innerWidth
            // let h = window.innerHeight
            if (w < h) {
                for (i in layoutDataP) {
                    if (w <= layoutDataP[i].size) {
                        layout = <AiLayout header={layoutDataP[i].header} footer={layoutDataP[i].footer}
                                           orientation={layoutDataP[i].orientation}
                                           image={layoutDataP[i].image} optionNumber={layoutDataP[i].optionNumber}
                                           optionSize={layoutDataP[i].optionSize}
                                           messages={this.messages}
                        />
                        break
                    }

                }
            }
            else {
                for (i in layoutDataL) {
                    if (w <= layoutDataL[i].size) {
                        layout = <AiLayout header={layoutDataL[i].header} footer={layoutDataL[i].footer}
                                           orientation={layoutDataL[i].orientation}
                                           image={layoutDataL[i].image} optionNumber={layoutDataL[i].optionNumber}
                                           optionSize={layoutDataL[i].optionSize}
                                           messages={this.messages}
                        />
                        break
                    }

                }
            }
        }
        if (this.state.error)
            layout = <AiLayout header='medium' footer='medium' orientation='error'/>
        return (

            <div className='ai-container'>
                <div role='log' aria-live="assertive">
                    <Growl ref={(el) => this.growl = el} position='bottomleft' baseZIndex={40000} />
                </div>
                <Messages
                    //style={{ margin: 'auto', position: 'absolute', zIndex: 20000, top: '0', left: '0', bottom: '0', right: '0'}}
                    ref={(el) => this.messages = el}></Messages>
                {layout}
                {/*<div style={{float:'right'}} >windows  w x h={this.state.width}x{this.state.height}
     <br/> document w x h={document.documentElement.clientWidth+'x'+document.documentElement.clientHeight}
     <br/> body w x h={document.documentElement.getElementsByTagName('body')[0].clientWidth+'x'+document.documentElement.getElementsByTagName('body')[0].clientHeight}
  </div>*/}
            </div>
        );
    }
}

export default App;
