import {
  ACTIONS,
  INITIAL_STATE,
  START_OVER_STATE,
  DEFAULT_STATE_VALUE,
  DEFAULT_ATTR_VALUE
}
from '../constants.js'
import {
  filter
}
from './utils'
import {
  param2sku,
  sku2array
}
from '../logic/sku-ver2.js'
import {
  get_default_sku
}
from './utils';
import {rules_eb1} from './rules_eb1';
import {rules_uinfo} from './rules_uinfo';
import {rules_ce} from './rules_ce';
import {rules_cec} from './rules_cec';
import {rules_ds} from './rules_ds';
import {rules_dctw} from './rules_dctw';
import {rules_dq} from './rules_dq';
import {rules_sh} from './rules_sh';
import {rules_pkb} from './rules_pkb';
//import {rules_pkbc} from './rules_pkbc';
import {rules_rset} from './rules_rset';
import {rules_rsetc} from './rules_rsetc';
import {rules_rsetl} from './rules_rsetl';
import {rules_ren} from './rules_ren';
//import {rules_renl} from './rules_renl';
import {rules_ben} from './rules_ben';
import {rules_benl} from './rules_benl';
import {rules_head} from './rules_head';
import {rules_band} from './rules_band';
import {rules_headc} from './rules_headc';
import {rules_bset} from './rules_bset';
import {rules_bsetc} from './rules_bsetc';
import {rules_bsetl} from './rules_bsetl';
import {rules_hmetc} from './rules_hmetc';
import {rules_bmetc} from './rules_bmetc';
import {rules_bmet} from './rules_bmet';
import {rules_smetc} from './rules_smetc';
import {set_default,includeRule} from './rules_functions';

function checkNewValue(state, newstate, value) {
  if (value in newstate)
      return newstate[value]
      return state[value]
}

//first change


function controlLogic(state, action) {
//console.log('controlLogic',action)
  //if (action.attr === 'ce')
  //console.log(state.sku,state.rs,action.attr, action.value)
  //console.log(state)

  let newState = {}
 // console.log('start reducer control logic', action, state)

  let option_ring_view1 = ["ce", "cec", "ds", "dctw", "", "rmet", "hmetc", "smetc", "rs", "sh"]
  let option_ring_view2 = ["pkb",  "ren", ]
  let option_ring_view3 = ["rset", "rsetc", "rsetl", ]
  let option_band_view1 = ["band", "bmet", "bmetc", ]
  let option_band_view2 = ["ben", "benl", ]
  let option_band_view3 = ["bset", "bsetc", "bsetl", ]
  let option_head = ["head"]
  let option_head_view2 = ["SS1", "SS2", "C1"]
  let option_headc = ["headc"]
  // change to active view =1 if sc changed
  if (action.attr === 'rset') {
    window.rset='done'
}

  if (state['band'] === '0') {
//       if (state['imageversion'] ==='6')
//            newState['max_view'] = 4
//       else
            newState['max_view'] = 3     
  } else {
      newState['max_view'] = 5
  }

  if (action.attr === 'band' && action.value === '0') {
      ////console.log('condition 1')
      newState['max_view'] = 3
          newState['active_view'] = 1
  } else
    if (action.attr === 'band' && action.value !== '0') {
        ////console.log('condition 2')
        newState['max_view'] = 5
            newState['active_view'] = 5
            newState['active_view5'] = 1
    } else
    if (option_ring_view1.includes(action.attr) && state['band'] === '0') {
        ////console.log('condition 3')
        newState['active_view'] = 1
    } else
    if (option_ring_view1.includes(action.attr) && state['band'] !== '0') {
        ////console.log('condition 4')
        newState['active_view'] = 4
            newState['active_view4'] = 1
    } else
    if (option_ring_view2.includes(action.attr) && state['band'] === '0') {
        ////console.log('condition 5')
        newState['active_view'] = 2
    } else
    if (option_ring_view2.includes(action.attr) && state['band'] !== '0') {
        ////console.log('condition 6')
        newState['active_view'] = 4
            newState['active_view4'] = 2
    } else
    if (option_ring_view3.includes(action.attr) && state['band'] === '0') {
        ////console.log('condition 7')
        newState['active_view'] = 3
    } else
    if (option_ring_view3.includes(action.attr) && state['band'] !== '0') {
        ////console.log('condition 8')
        newState['active_view'] = 4
            newState['active_view4'] = 3
    } else
    if (option_band_view1.includes(action.attr)) {
        ////console.log('condition 9')
        newState['active_view'] = 5
            newState['active_view5'] = 1
    } else
    if (option_band_view2.includes(action.attr)) {
        ////console.log('condition 10')
        newState['active_view'] = 5
            newState['active_view5'] = 2
    } else
    if (option_band_view3.includes(action.attr)) {
        ////console.log('condition 11')
        newState['active_view'] = 5
            newState['active_view5'] = 3
    } else
    if (option_head.includes(action.attr) && option_head_view2.includes(action.value) && state['band'] === '0') {
        ////console.log('condition 12')
        newState['active_view'] = 2
    } else
    if (option_head.includes(action.attr) && option_head_view2.includes(action.value) && state['band'] !== '0') {
        ////console.log('condition 13')
        newState['active_view'] = 4
            newState['active_view4'] = 2
    } else
    if (option_head.includes(action.attr) && !option_head_view2.includes(action.value) && state['band'] === '0') {
        ////console.log('condition 14')
        newState['active_view'] = 1
    } else
    if (option_head.includes(action.attr) && !option_head_view2.includes(action.value) && state['band'] !== '0') {
        ////console.log('condition 15')
        newState['active_view'] = 4
            newState['active_view4'] = 1
    } else
    if (option_headc.includes(action.attr) && option_head_view2.includes(state['head']) && state['band'] === '0') {
        ////console.log('condition 16')
        newState['active_view'] = 2
    } else
    if (option_headc.includes(action.attr) && option_head_view2.includes(state['head']) && state['band'] !== '0') {
        ////console.log('condition 17')
        newState['active_view'] = 4
            newState['active_view4'] = 2
    } else
    if (option_headc.includes(action.attr) && !option_head_view2.includes(state['head']) && state['band'] === '0') {
        ////console.log('condition 18')
        newState['active_view'] = 1
    } else
    if (option_headc.includes(action.attr) && !option_head_view2.includes(state['head']) && state['band'] !== '0') {
        ////console.log('condition 19')
        newState['active_view'] = 4
            newState['active_view4'] = 1
    }
  ////console.log('after view logic',newState['ud'],state['ud'],newState,action,state,action.attr,option_headc.includes(action.attr) ,
  //option_head_view2.includes(state['head']) , state['band'] )  


  // main logic to update  available options based on style json
  // with attribute effect_attr and effect_value in options with type 'control'
  // see template in admin-internal-template


    newState[action.attr] = action.value

    //console.log('rules',action,newState)
// RULES uinfo ------------------------------------------------------   
    newState['options_uinfo'] = rules_uinfo(newState,state)
//    newState['uinfo'] = set_default(newState,state,'uinfo')
//    console.log('ce rules',action.attr,newState['options_ce'].show,newState['ce'])
// RULES ce ------------------------------------------------------   
newState['options_ce'] = rules_ce(newState,state)
newState['ce'] = set_default(newState,state,'ce')
//    console.log('ce rules',action.attr,newState['options_ce'].show,newState['ce'])
// RULES cec ------------------------------------------------------   
newState['options_cec'] = rules_cec(newState,state)
newState['cec'] = set_default(newState,state,'cec')
//console.log('cec rules',action.attr,newState['options_cec'].show,newState['cec'])
// RULES ds ------------------------------------------------------   
newState['options_ds'] = rules_ds(newState,state)
newState['ds'] = set_default(newState,state,'ds')
//console.log('ds rules',action.attr,newState['options_ds'].show,newState['ds'])
    
// RULES dctw ------------------------------------------------------   
    
    
//console.log('dctw rules In',action.attr,newState['dctw'])
//state['dctw']=newState['dctw']

newState['options_dctw'] = rules_dctw(newState,state)
newState['dctw'] = set_default(newState,state,'dctw')
//newState['dctw'] = set_default(state,newState,'dctw')
//console.log('dctw rules',action.attr,newState['options_dctw'].show,newState['dctw'])
    
// RULES dq -----------------------------------------------------   
    newState['options_dq'] = rules_dq(newState,state)
    newState['dq'] = set_default(newState,state,'dq')
//    console.log('dq rules',action.attr,newState['options_dq'].show,newState['dq'])

// RULES head ------------------------------------------------------   
newState['options_head'] = rules_head(newState,state)
newState['head'] = set_default(newState,state,'head')
//console.log('head rules',action.attr,newState['options_head'].show,newState['head'])
// RULES headc ------------------------------------------------------   
newState['options_headc'] = rules_headc(newState,state)
newState['headc'] = set_default(newState,state,'headc')
//console.log('headc rules',action.attr,newState['options_headc'].show,newState['headc'])

// RULES sh ------------------------------------------------------   
newState['options_sh'] = rules_sh(newState,state)
newState['sh'] = set_default(newState,state,'sh')
//console.log('sh rules',action.attr,newState['options_sh'].show,newState['sh'])

// RULES pkb ------------------------------------------------------   
newState['options_pkb'] = rules_pkb(newState,state)
newState['pkb'] = set_default(newState,state,'pkb')
//console.log('pkb rules',action.attr,newState['options_pkb'].show,newState['pkb'])

// RULES pkbc ------------------------------------------------------   
//newState['options_pkbc'] = rules_pkbc(newState,state)
//newState['pkbc'] = set_default(newState,state,'pkbc')
//console.log('pkbc rules',action.attr,newState['options_pkbc'].show,newState['pkbc'])


// RULES rset ------------------------------------------------------   
newState['options_rset'] = rules_rset(newState,state)
//console.log('rset rules before',action.attr,newState['options_rset'].show,newState['rset'])
if ( action.attr!=='active_view'){
//if (window.testAB==='B' && window.rset==='0') {
    if ( window.rset==='0') {
        //console.log('changed rset')
         if (newState['sh']==='1') newState['rset']='0'
    else if (newState['sh']==='4') newState['rset']='UP'
    else if (newState['sh']==='5') newState['rset']='BD'
    else if (newState['sh']==='10') newState['rset']='0'
    else if (newState['sh']==='2') newState['rset']='PR'
    else if (newState['sh']==='11') newState['rset']='BD'
    else if (newState['sh']==='8') newState['rset']='PV'
    
}
else {
  //  console.log('changed default')
    
    newState['rset'] = set_default(newState,state,'rset')
}
}
//console.log('rset rules after',action.attr,newState['options_rset'].show,newState['rset'],window.rset)

// RULES rsetc ------------------------------------------------------   
newState['options_rsetc'] = rules_rsetc(newState,state)
newState['rsetc'] = set_default(newState,state,'rsetc')
//console.log('rsetc rules',action.attr,newState['options_rsetc'].show,newState['rsetc'])
// RULES rset ------------------------------------------------------   
newState['options_rsetl'] = rules_rsetl(newState,state)
newState['rsetl'] = set_default(newState,state,'rsetl')
//console.log('rsetl rules',action.attr,newState['options_rsetl'].show,newState['rsetl'])
// RULES ren ------------------------------------------------------   
newState['options_ren'] = rules_ren(newState,state)
newState['ren'] = set_default(newState,state,'ren')
//console.log('ren rules',action.attr,newState['options_ren'].show,newState['ren'])
// RULES renl ------------------------------------------------------   
//newState['options_renl'] = rules_renl(newState,state)
//newState['renl'] = set_default(newState,state,'renl')
//console.log('renl rules',action.attr,newState['options_renl'].show,newState['renl'])
// RULES hmetc ------------------------------------------------------   
newState['options_hmetc'] = rules_hmetc(newState,state)
newState['hmetc'] = set_default(newState,state,'hmetc')
//console.log('hmetc rules',action.attr,newState['options_hmetc'].show,newState['hmetc'])
// RULES smetc ------------------------------------------------------   
newState['options_smetc'] = rules_smetc(newState,state)
newState['smetc'] = set_default(newState,state,'smetc')
//console.log('smetc rules',action.attr,newState['options_smetc'].show,newState['smetc'])
if (newState['head']==='P1'){
    newState['hmetc'] = newState['smetc']
  //  console.log('hmetc update',newState['hmetc'])
}


if (action.attr === 'band' && action.value !== '0') {
// update band as ring
        newState['bset'] = newState['rset']
        newState['bsetc'] = newState['rsetc']
        newState['bsetl'] = newState['rsetl']
        newState['ben'] = newState['ren']
        newState['benl'] = newState['renl']
        newState['bmet'] = newState['rmet']
        newState['bmetc'] = newState['smetc']
        
}

// RULES band ------------------------------------------------------   
newState['options_band'] = rules_band(newState,state)
newState['band'] = set_default(newState,state,'band')
//console.log('band rules',action.attr,newState['options_band'].show,newState['band'])
// RULES bset ------------------------------------------------------   
newState['options_bset'] = rules_bset(newState,state)
newState['bset'] = set_default(newState,state,'bset')
//console.log('bset rules',action.attr,newState['options_bset'].show,newState['bset'])
// RULES bsetc ------------------------------------------------------   
newState['options_bsetc'] = rules_bsetc(newState,state)
newState['bsetc'] = set_default(newState,state,'bsetc')
//console.log('bsetc rules',action.attr,newState['options_bsetc'].show,newState['bsetc'])
// RULES bsetl ------------------------------------------------------   
newState['options_bsetl'] = rules_bsetl(newState,state)
newState['bsetl'] = set_default(newState,state,'bsetl')
//console.log('bsetl rules',action.attr,newState['options_bsetl'].show,newState['bsetl'])
// RULES ben ------------------------------------------------------   
newState['options_ben'] = rules_ben(newState,state)
newState['ben'] = set_default(newState,state,'ben')
//console.log('ben rules',action.attr,newState['options_ben'].show,newState['ben'])
// RULES benl ------------------------------------------------------   
newState['options_benl'] = rules_benl(newState,state)
newState['benl'] = set_default(newState,state,'benl')
//console.log('benl rules',action.attr,newState['options_benl'].show,newState['benl'])
// RULES bmet ------------------------------------------------------   
newState['options_bmet'] = rules_bmet(newState,state)
newState['bmet'] = set_default(newState,state,'bmet')
//console.log('bmet rules',action.attr,newState['options_bmet'].show,newState['bmet'])
// RULES bmetc ------------------------------------------------------   
newState['options_bmetc'] = rules_bmetc(newState,state)
newState['bmetc'] = set_default(newState,state,'bmetc')
//console.log('bmetc rules',action.attr,newState['options_bmetc'].show,newState['bmetc'])
// RULES eb1 ------------------------------------------------------   
newState['options_eb1'] = rules_eb1(newState,state)
//newState['bmetc'] = set_default(newState,state,'bmetc')
//console.log('bmetc rules',action.attr,newState['options_bmetc'].show,newState['bmetc'])
//console.log('benl',newState['benl'])
if (action.attr === 'ren') {
    if (action.value !=='0'){
        if (newState['rset']!=='0' && newState['rsetl']!=='2')
        newState['renl'] ='1'
        else
        newState['renl'] ='2'
        
    }}
if (action.attr === 'rset') {
     if (action.value !=='0'){
            if (newState['ren']!=='0' && newState['rsetl']!=='2')
            newState['renl'] ='1'
            else
            newState['renl'] ='2'
            
        } else
        newState['renl'] ='2'}
if (action.attr === 'rsetl') {
            if (newState['ren']!='0' && action.value !='2')
                   newState['renl'] ='1'
                   else
                   newState['renl'] ='2'
                   
               }
if (['ren','rsetl','rset'].includes(action.attr)){
    newState['benl'] = newState['renl']
} 
 if (action.attr === 'ben') {
                if (action.value !=='0'){
                    if (newState['bset']!=='0' && newState['bsetl']==='1')
                    newState['benl'] ='1'
                    else if (newState['bset']!=='0' && newState['bsetl']==='3')
                    newState['benl'] ='3'
                    else
                    newState['benl'] ='2'
                    
                }}
  //              console.log('benl2',newState['benl'])
 if (action.attr === 'bset') {
                 if (action.value !=='0'){
                        if (newState['ben']!=='0' && newState['bsetl']==='1')
                        newState['benl'] ='1'
                        else if (newState['ben']!=='0' && newState['bsetl']==='3')
                        newState['benl'] ='3'
                        else
                        newState['benl'] ='2'
                        
                    }
                    else
                    newState['benl'] ='2'}
//console.log('DICOMB',action)                        
if (action.attr === 'dicombine') {
    if (action.value !=='more')
    {
        var newrule = includeRule(newState, state, 'dicolor')
        newrule['show']=false
        newState['options_dicolor'] = newrule

        var newrule1 = includeRule(newState, state, 'diclarity')
        newrule1['show']=false
        newState['options_diclarity'] = newrule1

        var newrule2 = includeRule(newState, state, 'dicut')
        newrule2['show']=false
        newState['options_dicut'] = newrule2
    }
    else
    {
        var newrule = includeRule(newState, state, 'dicolor')
        newrule['show']=true
        newState['options_dicolor'] = newrule

        var newrule1 = includeRule(newState, state, 'diclarity')
        newrule1['show']=true
        newState['options_diclarity'] = newrule1

        var newrule2 = includeRule(newState, state, 'dicut')
        newrule2['show']=true
        newState['options_dicut'] = newrule2
    }
 
}    
if (action.attr === 'bsetl') {
                        if (newState['ben']!='0' && action.value ==='1')
                               newState['benl'] ='1'
                        else if (newState['ben']!='0' && action.value ==='3')
                               newState['benl'] ='3'
                        else
                               newState['benl'] ='2'
                               
                           }
                       
       
           

// update band if ring was changed
    let all_ring_options = ["ce", "cec", "ds", "dctw", "dq",
        "head", "headc", "sh", "pkb", "dicolor",
        "rset", "rsetc", "rsetl", "ren",
        "rmet", "hmetc", "smetc"]
    ////console.log('try to update band',action.attr,state['band'],newState)
    if (all_ring_options.includes(action.attr) && state['band'] !== '0') {
        if (action.attr === 'rset') {
            newState['bset'] = action.value
        } //   else {newState['bset']= state['rset']}
        if (action.attr === 'rsetc') {
            newState['bsetc'] = action.value
        } //   else {newState['bsetc']= state['rsetc']}
        if (action.attr === 'rsetl') {
            newState['bsetl'] = action.value
        } //   else {newState['bsetl']= state['rsetl']}
        if (action.attr === 'ren') {
            newState['ben'] = action.value
                
           

        } // else {newState['ben']= state['ren']}
       //   else {newState['benl']= state['renl']}
        if (action.attr === 'rmet') {
            newState['bmet'] = action.value
        } //  else {newState['bmet']= state['rmet']}
        if (action.attr === 'smetc') {
            if (state['bmet'] != 'PT')
                newState['bmetc'] = action.value
        } //   else {newState['bmetc']= state['smetc']}
        ////console.log('updated band',newState)
    }

  /*  if (action.attr === 'rset' && action.value !== '0') {
  //      if (state['rsetc'] === '0')
            newState['rsetc'] = DEFAULT_ATTR_VALUE['rsetc']
                if (state['rsetl'] !== '1' && state['rsetl'] !== '2')
                    newState['rsetl'] = DEFAULT_ATTR_VALUE['rsetl']
                        newState['bset'] = action.value
                        if (state['bsetc'] === '0')
                            newState['bsetc'] = DEFAULT_ATTR_VALUE['bsetc']
   //                             if (state['bsetl'] === '0')
   //                                 newState['bsetl'] = DEFAULT_ATTR_VALUE['rsetl']
    }*/
    if (action.attr === 'rmet' && action.value === 'PT') {
        newState['rmetc'] = '0'
            newState['bmet'] = 'PT'
            newState['bmetc'] = '0'

    }
 
 
   
            //newState[action.attr] = action.value //.toString()
       //     console.log('last newstate', newState)
            //console.log(newState['head'])
            return newState
}

export default function reducer(state = INITIAL_STATE, action) {
  if (action.attr !== undefined && action.value === state['action.attr']) {
      ////console.log('useless reducer for ', action.attr, '=', action.value)
      return state
  }
  ////console.log('try start reducer',state['ud'],action,state)
  var all_dynamic_options = ['ce', 'cec', 'ds', 'dctw', 'head', 'headc', 'sh',
      'pkb', 'rset', 'rsetc', 'rsetl', 'ren',  'band', 'bset',
      'bsetc', 'bsetl', 'rmet', 'hmetc', 'smetc', "dcl", "dct","dicolor",
      'bmet', 'bmetc', 'rs', 'ben', "dq", "loading", "ud", "isposterminal"]
  var newState
  var newState2
  var refParam
  //console.log(state)
  //console.log(action)
  //console.log(action.type, action.attr, action.value)
 // console.log('REDUCER---------------------------',action.type,action.attr,action.value)
  switch (action.type) {
  case 'REDUX-LOCATION-POP-ACTION':
      if (action.object !== undefined) {
          //action.object['sku']=action.object['sku']
           let refParam = sku2array(action.object['sku'])
              for (let i in refParam) {
                  ////console.log('param',i,refParam[i])
                  action.object[i] = refParam[i]
              }
      }
      return Object.assign({}, state, {
          ...action.object
      });
  case ACTIONS.START_OVER:
      window.rset='0'
     //console.log('reducer start_over1',state.loading);
      if (state.loading==='new')
      return Object.assign({},state,{})
         
      var json2 = JSON.parse(JSON.stringify(state.start_over_init));
      json2['showReview'] = false
          json2['sku'] = get_default_sku()
          json2['er'] = ''
     
          refParam = sku2array(get_default_sku())
          for (let i in refParam) {
              ////console.log('param',i,refParam[i])
              json2[i] = refParam[i]
          }
          json2['er'] = ''
          json2['loading'] = 'new'
          json2['eb1'] = ''

          if (state['isposterminal'] === 'true') {
            let getOp = state['options_ud']
                ////console.log('AFTER getOp',getOp,action.object)
                getOp['show'] = true
                json2['options_ud'] = getOp
        }
          //json2['sku']=get_sku(state)
          ////console.log('reducer start_over',json2,state);
          //      newState2.active_view ='1'
          //        return  Object.assign({}, state, {...state.start_over_init, ...newState2});
          return Object.assign({}, state, {
          ...START_OVER_STATE,
          ...json2
      });
      case ACTIONS.ZALESSKU:
          ////console.log('BEFORE ZALES SKU',action,action.object.value)
          newState = state
              newState['sku'] = action.object.value
              newState['cfg1'] = action.object.value
              newState['zalessku'] = ''
              ////console.log('from reducer.js 400', state.sku, state.zalessku)
              refParam = sku2array(action.object.value)
              for (let i in refParam) {                
                  ////console.log('paramH',i,refParam[i])
                  newState[i] = refParam[i]
              }
              // newState['active_view']=1
              ////console.log('AFTER ZALES SKU',refParam)
              newState2 = {};
          var i;
          for (i in all_dynamic_options)
              if (newState.hasOwnProperty(all_dynamic_options[i])) {
                  let val = {
                      attr: all_dynamic_options[i],
                      value: newState[all_dynamic_options[i]]
                  }
                  newState2 = controlLogic(newState, val);
              }
          ////console.log('AFTER HISTORY',newState,newState2)
    
          return Object.assign({}, state, {
              ...newState,
              ...newState2
          });
  case ACTIONS.BESTSELLER:
      ////console.log('BEFORE ZALES SKU',action,action.object.value)
      newState = state
          /* newState['sku'] = action.object.value
          newState['cfg1'] = action.object.value
          newState['zalessku'] = '' */
          ////console.log('from reducer.js 400', state.sku, state.zalessku)
          refParam = sku2array(action.object.value)

          refParam['rs'] = state.rs

          var newsku = param2sku(['R',refParam['ds'],refParam['ce'],refParam['dctw'],refParam['cec'],refParam['headc'],refParam['dq'],refParam['head'],refParam['sh'],refParam['pkb'],"D",refParam['rset'],refParam['rsetl'],refParam['rsetc'],refParam['ren'],refParam['rmet'],refParam['hmetc'],refParam['smetc'],refParam['renl'],refParam['rs'],'1',refParam['store'],refParam['band'],refParam['bset'],refParam['bsetc'],refParam['bsetl'],refParam['bmet'],refParam['bmetc'],refParam['rs'],refParam['ben'],refParam['benl'],'1','1'],8)
          
          //console.log(sku2array(action.object.value), refParam, ['R',refParam['ds'],refParam['ce'],refParam['dctw'],refParam['cec'],refParam['headc'],refParam['dq'],refParam['head'],refParam['sh'],refParam['pkb'],refParam['pkbc'],refParam['rset'],refParam['rsetl'],refParam['rsetc'],refParam['ren'],refParam['rmet'],refParam['hmetc'],refParam['smetc'],refParam['renl'],refParam['rs'],'1',refParam['store'],refParam['band'],refParam['bset'],refParam['bsetc'],refParam['bsetl'],refParam['bmet'],refParam['bmetc'],refParam['rs'],refParam['ben'],refParam['benl'],'1','1'], newsku, newState, newState2)

          newState['sku'] = newsku[0]
          newState['cfg1'] = newsku[0]
          newState['zalessku'] = ''
          
          for (let i in refParam) {
            /* if (i !== 'rs') {
                newState[i] = state.rs
            }
            else { */
                newState[i] = refParam[i]
            //}
          }
          newState2 = {};
      var i;
      var val;
      for (i in all_dynamic_options) {
          /* if (i === 'rs') {
              continue;
          } */
          if (newState.hasOwnProperty(all_dynamic_options[i])) {
              val = {
                  attr: all_dynamic_options[i],
                  value: newState[all_dynamic_options[i]]
              }
              ////console.log('Iteration',i,all_dynamic_options[i],val)
        
  //            newState2 = controlLogic(newState, val);
          }
        }
        newState2 = controlLogic(newState, val);
    
      ////console.log('AFTER HISTORY',newState,newState2)

      return Object.assign({}, state, {
          ...newState,
          ...newState2
      });
  case ACTIONS.HISTORY:
      ////console.log('history',action,state);
      if (state.zalessku !== '') {
          return state
      }
      //if (state.zalessku !== '' || action.value === state.sku){return state}
      newState = state
          newState['sku'] = action.value
          newState['cfg1'] = action.value
          ////console.log('from reducer.js 428', state.sku, state.zalessku, state)

          refParam = sku2array(action.value)
          for (let i in refParam) {
              ////console.log('paramH',i,refParam[i])
              newState[i] = refParam[i]
          }

          // newState['active_view']=1
          ////console.log('AFTER HISTORY SKU',newState['ud'],refParam)
          newState2 = {};
          var val={}
      for (i in all_dynamic_options)
          if (newState.hasOwnProperty(all_dynamic_options[i])) {
              val = {
                  attr: all_dynamic_options[i],
                  value: newState[all_dynamic_options[i]]
              }
              ////console.log('Iteration',i,all_dynamic_options[i],val)
              ////console.log('Iteration2',i,all_dynamic_options[i],controlLogic(newState,val))
              
          }
      newState2 = controlLogic(newState, val);
      if (state['er'] !== '') {
          newState2['loading-ring'] = 'new'
      }
      newState2['dcl'] = newState['dcl']
      //state['loading-'+ownProps.product]
 //    console.log('AFTER HISTORY',newState,newState2)

      return Object.assign({}, state, {
          ...newState,
          ...newState2
      });
  case ACTIONS.LOAD_JSON:
      state.json = 'loaded'

          // var newShowReview ={}
          ////console.log('reducer load_json',state);
          let options = action.object['options_all']

          // check all url parameters to match to available options
          for (let i in options) {
              let defaultValue = DEFAULT_STATE_VALUE
                  if (options[i] === 'f') {
                      defaultValue = '0'
                  }
                  if (['er', 'eb1'].indexOf(options[i]) === -1) {
                      if (state.hasOwnProperty(options[i]) !== DEFAULT_STATE_VALUE) {
                          let correctValue = false
                              ////console.log('opt',i,options[i],action)
                              for (let j in action.object['options_' + options[i]].options) {
                                  ////console.log('in for',action.object['options_'+options[i]] , state[options[i]])
                                  if (action.object['options_' + options[i]].options[j].value === state[options[i]]) {
                                      correctValue = true
                                          break
                                  }
                              }
                              if (correctValue === false) {
                                  ////console.log('orig val',options[i],state[options[i]])
                                  state[options[i]] = defaultValue
                                      ////console.log('changed val to default',options[i])
                              }
                      }
                  } else {
                      // option e
                      for (let j in action.object['options_' + options[i]].options) {
                          let type = 'engraving'

                              if (action.object['options_' + options[i]].options[j].hasOwnProperty('filter')) {
                                  type = action.object['options_' + options[i]].options[j]['filter']
                              }
                              ////console.log('in for',type,action.object['options_'+options[i]].options[j].maxLength ,options[i], state[action.object['options_'+options[i]].options[j]])
                              state[action.object['options_' + options[i]].options[j].attr] = filter(state[action.object['options_' + options[i]].options[j].attr],
                                  type, action.object['options_' + options[i]].options[j].maxLength)
                              ////console.log('result filter',action.object['options_'+options[i]].options[j].attr,state[action.object['options_'+options[i]].options[j].attr])
                      }
                  }
          }
          ////console.log('json.showReview',state,action)
          if (action.object['showReview'] === false)
              for (let i in options) {
                  // need better test on init values fir show review
                  if (options[i] !== 'e' && options[i] !== 'f' && state[options[i]] !== DEFAULT_STATE_VALUE) {
                      ////console.log('json.showReview found',options[i],state[options[i]] )
                      action.object['showReview'] = true
                          break
                  }

              }
          ////console.log('json.showReview2',newShowReview,state,action)
          if (action.object['sku'] !== undefined && action.object['sku'] !== state.sku) {
              //action.object['sku']=action.object['sku']
              ////console.log('from reducer.js 517', state.sku, state.zalessku)

              refParam = sku2array(action.object['sku'])
                  for (let i in refParam) {
                      ////console.log('param',i,refParam[i])
                      action.object[i] = refParam[i]
                  }
          }
          action.object['active_view'] = 1
          ////console.log('AFTER JSON INIT',action.object)
          newState = {};
      //var i;
      for (i in all_dynamic_options)
          if (action.object.hasOwnProperty(all_dynamic_options[i])) {
              let val = {
                  attr: all_dynamic_options[i],
                  value: action.object[all_dynamic_options[i]]
              }
              ////console.log('Iteration',i,all_dynamic_options[i],val)
              ////console.log('Iteration2',i,all_dynamic_options[i],controlLogic(action.object,val))
              newState = controlLogic(action.object, val);
          }
      //if (state['isposterminal']==='true' ||newState['isposterminal']==='true'){
      if (action.object['isposterminal'] === 'true' || state['isposterminal'] === 'true') {
          let getOp = action.object['options_ud']
              ////console.log('AFTER getOp',getOp,action.object)
              getOp['show'] = true
              newState['options_ud'] = getOp
      }
      ////console.log('AFTER JSON INIT2',newState)
      return Object.assign({}, state, {
          ...newState,
          ...action.object
      });
  case ACTIONS.FORCE_UPDATE:
      newState = {};
      newState2 = controlLogic(state, action, newState2);
      ////console.log('start reducer force',state,action,newState2)
      newState[action.attr] = action.value
          //newState['sku']=get_sku(state)
          ////console.log('newState2 force',newState2, {...newState, ...newState2})
          /* if (action.attr==='band' && action.value ==='0')
      {newState['max_views']=3}
          else
      {newState['max_views']=5}*/
          return Object.assign({}, state, {
          ...newState,
          ...newState2
      });

      //return state
      //  case ACTIONS.HISTORY:

  case ACTIONS.UPDATE:
  //    console.log('update -----------------------',action)
      newState = {};
      if (['ce', 'cec', 'ds', 'dctw', 'head', 'headc', 'sh',
      'pkb', 'rset', 'rsetc', 'rsetl', 'ren',  'band', 'bset',
      'bsetc', 'bsetl', 'rmet', 'hmetc', 'smetc', "dcl", "dct",
      'bmet', 'bmetc', 'rs', 'ben', "dq", "ud"].includes(action.attr.toString())){
        newState['last']=action.attr.toString()+'-'+action.value.toString()
        newState['loading']='done'
      }
      newState2 = controlLogic(state, action);
     
    // console.log('after control Logic',newState2)
    
      /* if (action.attr === "ud" && action.value === 'D' && state['dq'] === 'U') {
          newState = {
              'dq': 'I'
          }
      } */

      if (action.attr === "ud" && action.value === 'U') {
        newState = {
            'ce': 'UD',
            'dct':'',
            'dcl':''
        }
    }
    else if (action.attr === "ud" && action.value === 'D'){
        newState = {
            'ce': 'D'
        }    
    }
      

      ////console.log('check start reducer',action,state[action.attr])
      if (action.attr === "message") {
          newState = {
              'message': ''
          }
      }
      if (action.attr === "rs" && action.value !== '0') {
          newState = {
              'message': ''
          }
      }
      //if (state[action.attr] !== action.value) 
      {
     //     console.log('start reducer>',state,action,newState,newState2)
          newState[action.attr] = action.value
              let resObj = Object.assign({}, state, {
              ...newState,
              ...newState2
          });
          //resObj['sku']=get_sku(resObj)
          ////console.log('start changing sku because of ',action.attr,newState2)
          ////console.log('resobj',resObj['ud'],resObj,state, newState,newState2)
         //console.log('ini resobj',resObj)
          //if (all_dynamic_options.indexOf(action.attr) !== -1)
           {
              var state1 = {}
              state1['ds'] = checkNewValue(state, newState2, 'ds')
              state1['dctw'] = checkNewValue(state, newState2, 'dctw')

              if (checkNewValue(state, newState2, 'ud') === 'U') {
                state1['ce'] = 'UD'
                /* if (state1['dctw'].startsWith('C'))
                {
                    state1['dctw'] = '0.25'
                } */
              }
              else {
                state1['ce'] = checkNewValue(state, newState2, 'ce')
              }

              //state1['ce'] = checkNewValue(state, newState2, 'ce')
              state1['cec'] = checkNewValue(state, newState2, 'cec')
/*
              if (checkNewValue(state, newState2, 'ce') === 'D' || checkNewValue(state, newState2, 'ce') === 'UD') {
                 if (state1['dctw'].startsWith('C'))
                    state1['dctw'] = '0.25'
              }
              else {
                if (!state1['dctw'].startsWith('C'))
                    state1['dctw'] = 'C2'
              }
*/
              state1['band'] = checkNewValue(state, newState2, 'band')
              if (state1['band'] === '0') {
                state1['bset'] = '0'
              }
              else {
                state1['bset'] = checkNewValue(state, newState2, 'bset')
              }
                  
                  state1['head'] = checkNewValue(state, newState2, 'head')

                  //console.log(state['head'],newState2['head'])

                  state1['headc'] = checkNewValue(state, newState2, 'headc')
                  state1['sh'] = checkNewValue(state, newState2, 'sh')
                  state1['pkb'] = checkNewValue(state, newState2, 'pkb')
                  state1['pkbc'] = checkNewValue(state, newState2, 'pkbc')
                  state1['rset'] = checkNewValue(state, newState2, 'rset')
                  state1['rsetc'] = checkNewValue(state, newState2, 'rsetc')
                  state1['rsetl'] = checkNewValue(state, newState2, 'rsetl')
                  state1['ren'] = checkNewValue(state, newState2, 'ren')
                  state1['renl'] = checkNewValue(state, newState2, 'renl')
                  state1['bsetc'] = checkNewValue(state, newState2, 'bsetc')
                  state1['bsetl'] = checkNewValue(state, newState2, 'bsetl')
                  state1['rmet'] = checkNewValue(state, newState2, 'rmet')
                  state1['hmetc'] = checkNewValue(state, newState2, 'hmetc')
                  state1['smetc'] = checkNewValue(state, newState2, 'smetc')
                  state1['bmet'] = checkNewValue(state, newState2, 'bmet')
                  state1['bmetc'] = checkNewValue(state, newState2, 'bmetc')
                  state1['rs'] = checkNewValue(state, newState2, 'rs')
                  state1['ben'] = checkNewValue(state, newState2, 'ben')
                  state1['benl'] = checkNewValue(state, newState2, 'benl')

                  state1['dq'] = checkNewValue(state, newState2, 'dq')
                  state1['store'] = checkNewValue(state, newState2, 'store')
                  //      state1['ud']=checkNewValue(state,newState2,'ud')
                  state1[action.attr] = action.value
//                  console.log('state',action.attr.toString())
     //             if (action.attr.toString() === 'active_view')
     //             return {}
                      var newsku = param2sku(
                              ['R', state1['ds'], state1['ce'], state1['dctw'], state1['cec'],
                                  state1['headc'], state1['dq'], state1['head'], state1['sh'], state1['pkb'],
                                  "D", state1['rset'], state1['rsetl'], state1['rsetc'], state1['ren'],
                                  state1['rmet'], state1['hmetc'], state1['smetc'], state1['renl'], state1['rs'],
                                  '1', state1['store'], state1['band'], state1['bset'], state1['bsetc'],
                                  state1['bsetl'], state1['bmet'], state1['bmetc'], state1['rs'], state1['ben'],
                                  state1['benl'], '1', '1'
                              ],9)
                     //     console.log('new sku', newsku,state1['headc'], state1['dctw'], state1['sh'],']')
                    if (state['sku'] != newsku[0])
                     resObj['prevsku']=state['sku']
                          
                          resObj['sku'] = newsku[0]
                          resObj['cfg1'] = newsku[0]
                          resObj['sku-error'] = newsku[1]
          }

          ////console.log('new sku', resObj['ud'], resObj['sku'], resObj['sku-error'], state, resObj, state1)
     
      //    console.log('reducer newState',action,resObj)
          return resObj
      }
      return state
  default:
   //   console.log('default reducer with no logic',state,action)
      return state
  }

}